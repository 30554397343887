import React from 'react'
import styled from '@emotion/styled'
import Container from '../components/shared/container'
import { mediaQueries } from '../theme'
import { BaseLayout } from '../components/layout'
import BaseGallery, { Navbar } from '../components/gallery/gallery'
import PageMetadata from '../components/page-metadata'

const isBrowser = typeof window !== 'undefined'

const InnerContainer = styled(Container)`
  margin-bottom: ${p => p.theme.space[23]};

  ${mediaQueries.lg} {
    margin-bottom: ${p => p.theme.space[28]};
  }
`

const Gallery = styled(BaseGallery)`
  ${Navbar} {
    padding-top: ${p => p.theme.space[18]};
    top: 0;
  }
`

const TemplatesFrame = ({ location }) => (
  <BaseLayout location={location}>
    <PageMetadata robots="noindex" />
    {isBrowser ? (
      <InnerContainer>
        <Gallery withTitle={false} basePath="/templates-iframe" />
      </InnerContainer>
    ) : null}
  </BaseLayout>
)

export default TemplatesFrame
